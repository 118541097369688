<template>
  <CasinoLayout>
    <div class="container grid" style="padding: 8rem 0; max-width: 1024px;">
      <div class="bg-white center" style="--tw-bg-opacity: 0.95;max-width: 800px;margin: 0 auto;padding: 0 50px 50px 50px;">
        <div class="text-center pt-4 mb-4">
          <h2 class="py-4">{{ $t('casinoRegistration.title') }}</h2>
          <p style="line-height: 1.8">
            Wann: 28.05.2025 - Doors: 19:15 - Casino: 20:00<br />
            Wo:
            <a href="https://maps.app.goo.gl/VnX971paNkjhZCWm9" target="_blank"
              >Pfadiheim Virus Allmendingen</a
            ><br />
            Kosten: Entry + Chips 20.- (Bar/Twint)<br />
            Kosten für MCNA-Mitglieder: 15.-<br />
            Bar: Offen mit Kollekte<br />
            Anmeldeschluss: 30. April<br />
            Es handelt sich um einen Spendenanlass<br />
            <a target="_blank" href="spielregeln.zip">Spielregeln</a>
          </p>
        </div>
        <p v-if="invalid" class="error text-red-500 text-center mb-2">
          <b>{{ $t('casinoRegistration.error') }}</b>
        </p>
        <p v-if="registered" class="error text-green-500 text-center mb-2">
          <b>{{ $t('casinoRegistration.registered') }}</b>
        </p>
        <div v-else
          class="max-w-s mt-4 px-3 pb-3 content-center"
          style="margin: 0 auto"
        >
          <p class="mt-3">{{ $t('casinoRegistration.firstname') }} <span class="text-red-600">*</span></p>
          <input
            v-model="firstname"
            required
            class="
              shadow
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              mb-2
              text-gray-700
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          />
          <p class="mt-3">{{ $t('casinoRegistration.lastname') }} <span class="text-red-600">*</span></p>
          <input
            v-model="lastname"
            required
            class="
              shadow
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              mb-2
              text-gray-700
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          />

          <p class="mt-4">{{ $t('casinoRegistration.mail') }} <span class="text-red-600">*</span></p>
          <input
            v-model="email"
            required
            type="email"
            class="
              shadow
              appearance-none
              border
              rounded
              w-full
              py-2
              px-3
              mb-2
              text-gray-700
              leading-tight
              focus:outline-none focus:shadow-outline
            "
          />
          <input
            v-model="donation"
            id="donation"
            class="mt-4"
            type="checkbox"
            required
          />
          <label for="donation">
            Ich verstehe, dass kein Geld zurückgewonnen werden kann <span class="text-red-600">*</span>
          </label>
          <br />
          <input
            v-model="etiquette"
            id="etiquette"
            class="mt-4"
            type="checkbox"
            required
          />
          <label for="etiquette">
            Ich habe die <a target="_blank" href="casino_etiquette.pdf">Casino Etiquette</a> gelesen und verstanden <span class="text-red-600">*</span>
          </label>
          <br />
          <input
            v-model="newsletter"
            id="newsletter"
            class="mt-4"
            type="checkbox"
          />
          <label for="newsletter">
            Ich möchte den Casino-Newsletter erhalten (1-2 E-Mails pro Jahr)
          </label>
          <br />
          <button
            type="submit"
            class="bg-black px-2 mt-8 py-2 btn text-white hover:cursor-pointer"
            @click="sendRegistration"
          >
            {{ $t('casinoRegistration.submit') }}
            <svg-icon
              class="inline-block w-5 h-5 -mt-1 ml-1 mr-1 text-whitesvg-icon"
              name="submit"
            />
          </button>
        </div>
      </div>
    </div>
  </CasinoLayout>
</template>

<script>
import { saveCasinoRegistration } from '@/services/camping';
import CasinoLayout from '@/layouts/CasinoLayout.vue';

export default {
  name: 'Registration',
  components: { CasinoLayout },
  data() {
    return {
      invalid: false,
      registered: false,
      firstname: '',
      lastname: '',
      email: '',
      newsletter: false,
      etiquette: false,
      donation: false,
    };
  },
  methods: {
    validateEmail(email) {
      return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
        email
      );
    },
    async sendRegistration() {
      let error = false;
      if (
        !this.firstname ||
        !this.lastname ||
        !this.email ||
        !this.donation ||
        !this.etiquette ||
        !this.validateEmail(this.email)
      ) {
        error = true;
      }
      if (error) {
        this.invalid = true;
        return;
      }
      this.invalid = false;
      let payload = {
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        newsletter: this.newsletter,
      };
      await saveCasinoRegistration(payload);
      this.firstname = '';
      this.lastname = '';
      this.email = '';
      this.registered = true;
    },
  },
};
</script>
