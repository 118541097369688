const Dashboard = () => import('@/views/Dashboard.vue');
const Zelten = () => import('@/views/Zelten.vue');
const Casino = () => import('@/views/Casino.vue');
const Registration = () => import('@/views/Registration.vue');
const CasinoRegistration = () => import('@/views/CasinoRegistration.vue');

export default [
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/zelten',
    name: 'zelten',
    component: Zelten,
  },
  {
    path: '/casino',
    name: 'casino',
    component: CasinoRegistration,
  },
  {
    path: '/zelten/registration',
    name: 'registration',
    component: Registration,
  },
];
