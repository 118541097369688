<template>
  <div class="app-master">
    <div class="app-content">
      <header class="py-4 text-white bg-black w-full fixed">
        <div class="container">
          <router-link class="text-lg font-semibold no-underline" to="/">
            <img :src="logoPath" alt="logo" class="app-logo" />
          </router-link>
        </div>
      </header>
      <main>
        <slot></slot>
      </main>
    </div>

    <footer class="py-4 text-center text-white bg-black app-footer">
      <div class="container">
        <p class="text-lg font-semibold" v-html="$t('general.footerText')"></p>
      </div>
    </footer>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('Auth', ['firstName', 'lastName']),
    ...mapGetters('Auth', ['isLoggedIn']),
    getName() {
      return this.firstName + ' ' + this.lastName;
    },
    logoPath() {
      return require(`@/assets/images/logo.png`);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('Auth/removeAuth');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style>
.app-logo {
  max-height: 50px;
  margin: 0 auto;
}
</style>
