var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultLayout",
    [
      _c(
        "div",
        {
          staticClass: "text-center py-48 text-white bg-black",
          attrs: { id: "teaser-image" }
        },
        [
          _c("h1", { staticClass: "text-6xl" }, [
            _vm._v(_vm._s(_vm.$t("dashboard.title")))
          ]),
          _c("p", { staticClass: "text-4xl my-4" }, [
            _vm._v(_vm._s(_vm.$t("dashboard.subtitle")))
          ])
        ]
      ),
      _c(
        "a",
        {
          staticClass: "no-underline",
          attrs: { href: "https://www.nintendo64.ch/", target: "_blank" }
        },
        [
          _c(
            "div",
            {
              staticClass: "text-center py-48 text-white",
              style: _vm.backgroundImageN64
            },
            [
              _c("h1", { staticClass: "text-6xl text-white" }, [
                _vm._v(_vm._s(_vm.$t("n64.title")))
              ]),
              _c("p", { staticClass: "text-4xl" }, [
                _vm._v(_vm._s(_vm.$t("n64.date")))
              ])
            ]
          )
        ]
      ),
      _c(
        "router-link",
        { staticClass: "no-underline", attrs: { to: { name: "casino" } } },
        [
          _c(
            "div",
            {
              staticClass: "text-center py-48 text-white",
              style: _vm.backgroundImageCasino
            },
            [
              _c("h1", { staticClass: "text-6xl" }, [
                _vm._v(_vm._s(_vm.$t("casino.title")))
              ]),
              _c("p", { staticClass: "text-4xl" }, [
                _vm._v(_vm._s(_vm.$t("casino.date")))
              ])
            ]
          )
        ]
      ),
      _c(
        "router-link",
        { staticClass: "no-underline", attrs: { to: { name: "zelten" } } },
        [
          _c(
            "div",
            {
              staticClass: "text-center py-48 text-white no-underline",
              style: _vm.backgroundImageZelten
            },
            [
              _c("h1", { staticClass: "text-6xl text-white" }, [
                _vm._v(_vm._s(_vm.$t("zelten.title")))
              ]),
              _c("p", { staticClass: "text-4xl" }, [
                _vm._v(_vm._s(_vm.$t("zelten.date")))
              ])
            ]
          )
        ]
      ),
      _c("div", { staticClass: "no-underline" }, [
        _c(
          "div",
          {
            staticClass: "text-center py-48 text-white no-underline",
            style: _vm.backgroundImageLan
          },
          [
            _c("h1", { staticClass: "text-6xl" }, [
              _vm._v(_vm._s(_vm.$t("lan.title")))
            ]),
            _c("p", { staticClass: "text-4xl" }, [
              _vm._v(_vm._s(_vm.$t("lan.date")))
            ])
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }