var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-master" }, [
    _c("div", { staticClass: "app-content" }, [
      _c("header", { staticClass: "py-4 text-white bg-black w-full fixed" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c(
              "router-link",
              {
                staticClass: "text-lg font-semibold no-underline",
                attrs: { to: "/" }
              },
              [
                _c("img", {
                  staticClass: "app-logo",
                  attrs: { src: _vm.logoPath, alt: "logo" }
                })
              ]
            )
          ],
          1
        )
      ]),
      _c("main", [_vm._t("default")], 2)
    ]),
    _c(
      "footer",
      { staticClass: "py-4 text-center text-white bg-black app-footer" },
      [
        _c("div", { staticClass: "container" }, [
          _c("p", {
            staticClass: "text-lg font-semibold",
            domProps: { innerHTML: _vm._s(_vm.$t("general.footerText")) }
          })
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }