<template>
  <DefaultLayout>
    <div
      id="teaser-image"
      class="text-center py-48 text-white"
      :style="backgroundImage"
    >
      <h1 class="text-6xl">{{ $t('zelten.title') }}</h1>
      <p class="text-4xl my-4">{{ $t('zelten.date') }}</p>
    </div>
    <div class="w-full float-left">
      <div class="w-full md:w-2/5 p-6 text-center float-left">
        <h2>{{ $t('zelten.whereTitle') }}</h2>
        <div v-html="$t('zelten.whereText')"></div>
        <h2 class="mt-16">{{ $t('zelten.whenTitle') }}</h2>
        <div v-html="$t('zelten.whenText')"></div>
      </div>
      <div class="w-full md:w-3/5 float-left">
        <img class="w-full" :src="require(`@/assets/images/image1.png`)" />
      </div>
    </div>
    <div class="w-full float-left">
      <div class="hidden md:block w-2/5 float-left">
        <img class="w-full" :src="require(`@/assets/images/image2.jpg`)" />
      </div>
      <div class="w-full md:w-3/5 p-6 text-center float-left">
        <h2>{{ $t('zelten.howTitle') }}</h2>
        <div v-html="$t('zelten.howText')"></div>
        <h2 class="mt-16">{{ $t('zelten.coastTitle') }}</h2>
        <div v-html="$t('zelten.coastText')"></div>
        <h2 class="mt-16">{{ $t('zelten.whatTitle') }}</h2>
        <div v-html="$t('zelten.whatText')"></div>
      </div>


    </div>
    <div
      class="w-full p-8 inline-block text-center bg-blue-500 text-white"
      v-html="$t('zelten.contact')"
    ></div>
  </DefaultLayout>
</template>

<script>
export default {
  name: 'Zelten',
  computed: {
    backgroundImage() {
      return (
        'background-image: url("' +
        require(`@/assets/images/header.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
  },
};
</script>
