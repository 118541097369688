var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CasinoLayout", [
    _c(
      "div",
      {
        staticClass: "container grid",
        staticStyle: { padding: "8rem 0", "max-width": "1024px" }
      },
      [
        _c(
          "div",
          {
            staticClass: "bg-white center",
            staticStyle: {
              "--tw-bg-opacity": "0.95",
              "max-width": "800px",
              margin: "0 auto",
              padding: "0 50px 50px 50px"
            }
          },
          [
            _c("div", { staticClass: "text-center pt-4 mb-4" }, [
              _c("h2", { staticClass: "py-4" }, [
                _vm._v(_vm._s(_vm.$t("casinoRegistration.title")))
              ]),
              _c("p", { staticStyle: { "line-height": "1.8" } }, [
                _vm._v(" Wann: 28.05.2025 - Doors: 19:15 - Casino: 20:00"),
                _c("br"),
                _vm._v(" Wo: "),
                _c(
                  "a",
                  {
                    attrs: {
                      href: "https://maps.app.goo.gl/VnX971paNkjhZCWm9",
                      target: "_blank"
                    }
                  },
                  [_vm._v("Pfadiheim Virus Allmendingen")]
                ),
                _c("br"),
                _vm._v(" Kosten: Entry + Chips 20.- (Bar/Twint)"),
                _c("br"),
                _vm._v(" Kosten für MCNA-Mitglieder: 15.-"),
                _c("br"),
                _vm._v(" Bar: Offen mit Kollekte"),
                _c("br"),
                _vm._v(" Anmeldeschluss: 30. April"),
                _c("br"),
                _vm._v(" Es handelt sich um einen Spendenanlass ")
              ])
            ]),
            _vm.invalid
              ? _c(
                  "p",
                  { staticClass: "error text-red-500 text-center mb-2" },
                  [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("casinoRegistration.error")))
                    ])
                  ]
                )
              : _vm._e(),
            _vm.registered
              ? _c(
                  "p",
                  { staticClass: "error text-green-500 text-center mb-2" },
                  [
                    _c("b", [
                      _vm._v(_vm._s(_vm.$t("casinoRegistration.registered")))
                    ])
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "max-w-s mt-4 px-3 pb-3 content-center",
                    staticStyle: { margin: "0 auto" }
                  },
                  [
                    _c("p", { staticClass: "mt-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("casinoRegistration.firstname")) + " "
                      ),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.firstname,
                          expression: "firstname"
                        }
                      ],
                      staticClass:
                        "\n            shadow\n            appearance-none\n            border\n            rounded\n            w-full\n            py-2\n            px-3\n            mb-2\n            text-gray-700\n            leading-tight\n            focus:outline-none focus:shadow-outline\n          ",
                      attrs: { required: "" },
                      domProps: { value: _vm.firstname },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.firstname = $event.target.value
                        }
                      }
                    }),
                    _c("p", { staticClass: "mt-3" }, [
                      _vm._v(
                        _vm._s(_vm.$t("casinoRegistration.lastname")) + " "
                      ),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.lastname,
                          expression: "lastname"
                        }
                      ],
                      staticClass:
                        "\n            shadow\n            appearance-none\n            border\n            rounded\n            w-full\n            py-2\n            px-3\n            mb-2\n            text-gray-700\n            leading-tight\n            focus:outline-none focus:shadow-outline\n          ",
                      attrs: { required: "" },
                      domProps: { value: _vm.lastname },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.lastname = $event.target.value
                        }
                      }
                    }),
                    _c("p", { staticClass: "mt-4" }, [
                      _vm._v(_vm._s(_vm.$t("casinoRegistration.mail")) + " "),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.email,
                          expression: "email"
                        }
                      ],
                      staticClass:
                        "\n            shadow\n            appearance-none\n            border\n            rounded\n            w-full\n            py-2\n            px-3\n            mb-2\n            text-gray-700\n            leading-tight\n            focus:outline-none focus:shadow-outline\n          ",
                      attrs: { required: "", type: "email" },
                      domProps: { value: _vm.email },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.email = $event.target.value
                        }
                      }
                    }),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.donation,
                          expression: "donation"
                        }
                      ],
                      staticClass: "mt-4",
                      attrs: { id: "donation", type: "checkbox", required: "" },
                      domProps: {
                        checked: Array.isArray(_vm.donation)
                          ? _vm._i(_vm.donation, null) > -1
                          : _vm.donation
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.donation,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.donation = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.donation = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.donation = $$c
                          }
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "donation" } }, [
                      _vm._v(
                        " Ich verstehe, dass kein Geld zürückgewonnen werden kann "
                      ),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c("br"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.etiquette,
                          expression: "etiquette"
                        }
                      ],
                      staticClass: "mt-4",
                      attrs: {
                        id: "etiquette",
                        type: "checkbox",
                        required: ""
                      },
                      domProps: {
                        checked: Array.isArray(_vm.etiquette)
                          ? _vm._i(_vm.etiquette, null) > -1
                          : _vm.etiquette
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.etiquette,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.etiquette = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.etiquette = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.etiquette = $$c
                          }
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "etiquette" } }, [
                      _vm._v(" Ich habe die "),
                      _c(
                        "a",
                        {
                          attrs: {
                            target: "_blank",
                            href: "casino_etiquette.pdf"
                          }
                        },
                        [_vm._v("Casino Etiquette")]
                      ),
                      _vm._v(" gelesen und verstanden "),
                      _c("span", { staticClass: "text-red-600" }, [_vm._v("*")])
                    ]),
                    _c("br"),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newsletter,
                          expression: "newsletter"
                        }
                      ],
                      staticClass: "mt-4",
                      attrs: { id: "newsletter", type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.newsletter)
                          ? _vm._i(_vm.newsletter, null) > -1
                          : _vm.newsletter
                      },
                      on: {
                        change: function($event) {
                          var $$a = _vm.newsletter,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.newsletter = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.newsletter = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.newsletter = $$c
                          }
                        }
                      }
                    }),
                    _c("label", { attrs: { for: "newsletter" } }, [
                      _vm._v(
                        " Ich möchte den Casino-Newsletter erhalten (1-2 E-Mails pro Jahr) "
                      )
                    ]),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass:
                          "bg-black px-2 mt-8 py-2 btn text-white hover:cursor-pointer",
                        attrs: { type: "submit" },
                        on: { click: _vm.sendRegistration }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("casinoRegistration.submit")) +
                            " "
                        ),
                        _c("svg-icon", {
                          staticClass:
                            "inline-block w-5 h-5 -mt-1 ml-1 mr-1 text-whitesvg-icon",
                          attrs: { name: "submit" }
                        })
                      ],
                      1
                    )
                  ]
                )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }