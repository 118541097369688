<template>
  <DefaultLayout>
    <div
        id="teaser-image"
        class="text-center py-48 text-white bg-black"
    >
      <h1 class="text-6xl">{{ $t('dashboard.title') }}</h1>
      <p class="text-4xl my-4">{{ $t('dashboard.subtitle') }}</p>
    </div>
    <router-link
        :to="{ name: 'zelten'}"
        class="no-underline">
      <div
          id="teaser-image"
          class="text-center py-48 text-white no-underline"
          :style="backgroundImageZelten"
      >
        <h1 class="text-6xl text-white">{{ $t('zelten.title') }}</h1>

      </div>
    </router-link>
    <router-link
        :to="{ name: 'casino'}"
        class="no-underline">
      <div
          id="teaser-image"
          class="text-center py-48 text-white"
          :style="backgroundImageCasino"
      >

        <h1 class="text-6xl text-white">{{ $t('casino.title') }}</h1>

      </div>
    </router-link>
  </DefaultLayout>
</template>

<script>
export default {
  name: 'Zelten',
  computed: {
    backgroundImageZelten () {
      return (
          'background-image: url("' +
          require(`@/assets/images/header.jpg`) +
          '");' +
          'background-position: center center;' +
          'background-size: cover;'
      );
    },
    backgroundImageCasino () {
      return (
          'background-image: url("' +
          require(`@/assets/images/header_casino.jpg`) +
          '");' +
          'background-position: center center;' +
          'background-size: cover;'
      );
    },
  },
};
</script>
