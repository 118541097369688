<template>
  <DefaultLayout>
    <div id="teaser-image" class="text-center py-48 text-white bg-black">
      <h1 class="text-6xl">{{ $t('dashboard.title') }}</h1>
      <p class="text-4xl my-4">{{ $t('dashboard.subtitle') }}</p>
    </div>
    <a href="https://www.nintendo64.ch/" target="_blank" class="no-underline">
      <div class="text-center py-48 text-white" :style="backgroundImageN64">
        <h1 class="text-6xl text-white">{{ $t('n64.title') }}</h1>
        <p class="text-4xl">{{ $t('n64.date') }}</p>
      </div>
    </a>
    <router-link :to="{ name: 'casino' }" class="no-underline">
      <div class="text-center py-48 text-white" :style="backgroundImageCasino">
        <h1 class="text-6xl">{{ $t('casino.title') }}</h1>
        <p class="text-4xl">{{ $t('casino.date') }}</p>
      </div>
    </router-link>
    <router-link :to="{ name: 'zelten' }" class="no-underline">
      <div
        class="text-center py-48 text-white no-underline"
        :style="backgroundImageZelten"
      >
        <h1 class="text-6xl text-white">{{ $t('zelten.title') }}</h1>
        <p class="text-4xl">{{ $t('zelten.date') }}</p>
      </div>
    </router-link>
    <div class="no-underline">
      <div
        class="text-center py-48 text-white no-underline"
        :style="backgroundImageLan"
      >
        <h1 class="text-6xl">{{ $t('lan.title') }}</h1>
        <p class="text-4xl">{{ $t('lan.date') }}</p>
      </div>
    </div>
  </DefaultLayout>
</template>

<script>
export default {
  name: 'Zelten',
  computed: {
    backgroundImageZelten() {
      return (
        'background-image: url("' +
        require(`@/assets/images/header.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
    backgroundImageN64() {
      return (
        'background-image: url("' +
        require(`@/assets/images/nintendo.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
    backgroundImageCasino() {
      return (
        'background-image: url("' +
        require(`@/assets/images/casion_header.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
    backgroundImageLan() {
      return (
        'background-image: url("' +
        require(`@/assets/images/lan.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
  },
};
</script>
