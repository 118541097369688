var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DefaultLayout", [
    _c(
      "div",
      {
        staticClass: "text-center py-48 text-white",
        style: _vm.backgroundImage,
        attrs: { id: "teaser-image" }
      },
      [
        _c("h1", { staticClass: "text-6xl" }, [
          _vm._v(_vm._s(_vm.$t("zelten.title")))
        ]),
        _c("p", { staticClass: "text-4xl my-4" }, [
          _vm._v(_vm._s(_vm.$t("zelten.date")))
        ])
      ]
    ),
    _c("div", { staticClass: "w-full float-left" }, [
      _c("div", { staticClass: "w-full md:w-2/5 p-6 text-center float-left" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("zelten.whereTitle")))]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.whereText")) }
        }),
        _c("h2", { staticClass: "mt-16" }, [
          _vm._v(_vm._s(_vm.$t("zelten.whenTitle")))
        ]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.whenText")) }
        })
      ]),
      _c("div", { staticClass: "w-full md:w-3/5 float-left" }, [
        _c("img", {
          staticClass: "w-full",
          attrs: { src: require("@/assets/images/image1.png") }
        })
      ])
    ]),
    _c("div", { staticClass: "w-full float-left" }, [
      _c("div", { staticClass: "hidden md:block w-2/5 float-left" }, [
        _c("img", {
          staticClass: "w-full",
          attrs: { src: require("@/assets/images/image2.jpg") }
        })
      ]),
      _c("div", { staticClass: "w-full md:w-3/5 p-6 text-center float-left" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("zelten.howTitle")))]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.howText")) }
        }),
        _c("h2", { staticClass: "mt-16" }, [
          _vm._v(_vm._s(_vm.$t("zelten.coastTitle")))
        ]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.coastText")) }
        }),
        _c("h2", { staticClass: "mt-16" }, [
          _vm._v(_vm._s(_vm.$t("zelten.whatTitle")))
        ]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.whatText")) }
        })
      ])
    ]),
    _c("div", {
      staticClass: "w-full p-8 inline-block text-center bg-blue-500 text-white",
      domProps: { innerHTML: _vm._s(_vm.$t("zelten.contact")) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }