<template>
  <div class="app-master" :style="backgroundImageCasino">
    <div class="app-content">
      <header class="py-4 text-white bg-black w-full fixed">
        <div class="container">
          <router-link class="text-lg font-semibold no-underline" to="/">
            <img :src="logoPath" alt="logo" class="app-logo" />
          </router-link>
        </div>
      </header>
      <main>
        <slot></slot>
      </main>
    </div>

    <footer class="py-4 text-center text-white bg-black app-footer">
      <div class="container">
        <p class="text-lg font-semibold" v-html="$t('general.footerText')"></p>
      </div>
    </footer>
  </div>
</template>

<script>
import SvgIcon from '@/components/SvgIcon.vue';

export default {
  computed: {
    backgroundImageCasino () {
      return (
          'background-image: url("' +
          require(`@/assets/images/casino.jpg`) +
          '");' +
          'background-position: center center;' +
          'background-size: cover;'
      );
    },
    logoPath() {
      return require(`@/assets/images/logo.png`);
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('Auth/removeAuth');
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style>
.app-logo {
  max-height: 50px;
  margin: 0 auto;
}
</style>
